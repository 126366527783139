define("ui/components/resource-quota-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+q+Ei2yt",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"content\",\"value\",\"localizedLabel\",\"readOnly\"],[[35,3],[35,2,[\"key\"]],true,[30,[36,1],[[35,0]],null]]]]]],\"hasEval\":false,\"upvars\":[\"editing\",\"not\",\"quota\",\"resourceChoices\",\"searchable-select\"]}",
    "moduleName": "ui/components/resource-quota-select/template.hbs"
  });

  _exports.default = _default;
});