define("ui/pod-graphs/controller", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    scope: Ember.inject.service(),
    monitoringEnabled: Ember.computed.alias('scope.currentCluster.isMonitoringReady'),
    podStateDidChange: Ember.observer('model.state', function () {
      if (_constants.default.REMOVEDISH_STATES.includes(Ember.get(this, 'model.state')) && Ember.get(this, 'router.currentRouteName') === 'pod') {
        const workloadId = Ember.get(this, 'model.workloadId');

        if (workloadId) {
          this.transitionToRoute('workload', workloadId);
        } else {
          this.transitionToRoute('authenticated.project.index');
        }
      }
    })
  });

  _exports.default = _default;
});