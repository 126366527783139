define("ui/models/cloudcredential", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const cloudCredential = _resource.default.extend({
    modal: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    nodeTemplates: (0, _denormalize.hasMany)('id', 'nodetemplate', 'cloudCredentialId', 'globalStore'),
    type: 'cloudCredential',
    canClone: false,
    canEdit: true,
    isAmazon: Ember.computed.notEmpty('amazonec2credentialConfig'),
    isAzure: Ember.computed.notEmpty('azurecredentialConfig'),
    isDo: Ember.computed.notEmpty('digitaloceancredentialConfig'),
    isGoogle: Ember.computed.notEmpty('googlecredentialConfig'),
    isHarvester: Ember.computed.notEmpty('harvestercredentialConfig'),
    isLinode: Ember.computed.notEmpty('linodecredentialConfig'),
    isOCI: Ember.computed.notEmpty('ocicredentialConfig'),
    isPNAP: Ember.computed.notEmpty('pnapcredentialConfig'),
    isVMware: Ember.computed.notEmpty('vmwarevspherecredentialConfig'),
    displayType: Ember.computed('amazonec2credentialConfig', 'azurecredentialConfig', 'digitaloceancredentialConfig', 'harvestercredentialConfig', 'googlecredentialConfig', 'linodecredentialConfig', 'ocicredentialConfig', 'pnapcredentialConfig', 'vmwarevspherecredentialConfig', function () {
      const {
        isAmazon,
        isAzure,
        isDo,
        isGoogle,
        isLinode,
        isOCI,
        isPNAP,
        isVMware,
        isHarvester
      } = this;

      if (isAmazon) {
        return 'Amazon';
      } else if (isAzure) {
        return 'Azure';
      } else if (isDo) {
        return 'Digital Ocean';
      } else if (isGoogle) {
        return 'Google';
      } else if (isLinode) {
        return 'Linode';
      } else if (isOCI) {
        return 'OCI';
      } else if (isPNAP) {
        return 'phoenixNAP';
      } else if (isVMware) {
        return 'VMware vSphere';
      } else if (isHarvester) {
        return 'Harvester';
      }

      return '';
    }),
    numberOfNodeTemplateAssociations: Ember.computed('nodeTemplates.[]', function () {
      return Ember.get(this, 'nodeTemplates').length;
    }),
    actions: {
      edit() {
        this.modal.toggleModal('modal-add-cloud-credential', {
          cloudCredential: this,
          mode: 'edit'
        });
      }

    }
  });

  var _default = cloudCredential;
  _exports.default = _default;
});