define("ui/components/modal-edit-certificate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nBiterwk",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"horizontal-form container-fluid\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"editCertificate.title\"],null]],[13],[2,\"\\n\\n  \"],[1,[30,[36,2],null,[[\"model\",\"nameRequired\",\"descriptionShown\",\"namePlaceholder\",\"descriptionPlaceholder\"],[[35,1],true,false,\"editCertificate.name.placeholder\",\"editCertificate.description.placeholder\"]]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"banner bg-info\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"banner-icon\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon icon-info\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"banner-message pt-10 pb-10\"],[12],[1,[30,[36,0],[\"editCertificate.noteKeyWriteOnly\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,4],null,[[\"model\"],[[35,3]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,6],null,[[\"errors\"],[[35,5]]]]],[2,\"\\n\"],[1,[30,[36,8],null,[[\"editing\",\"save\",\"cancel\"],[true,[30,[36,7],[[32,0],\"save\"],null],[30,[36,7],[[32,0],\"cancel\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"primaryResource\",\"form-name-description\",\"model\",\"cru-certificate\",\"errors\",\"top-errors\",\"action\",\"save-cancel\"]}",
    "moduleName": "ui/components/modal-edit-certificate/template.hbs"
  });

  _exports.default = _default;
});