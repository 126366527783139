define("ui/components/volume-source/source-custom-log-path/component", ["exports", "ui/components/volume-source/source-custom-log-path/template", "shared/mixins/volume-source"], function (_exports, _template, _volumeSource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const formats = ['json', 'apache2', 'nginx', 'rfc3164', 'rfc5424', 'none'].map(value => ({
    value,
    label: value
  }));

  var _default = Ember.Component.extend(_volumeSource.default, {
    layout: _template.default,
    formats,
    useCustomRegex: false,
    cachedFormat: null,
    field: 'flexVolume',
    initialCustomFormat: null,

    init() {
      this._super(...arguments);

      const format = Ember.get(this, 'config.options.format');

      if (formats.every(item => item.value !== format)) {
        Ember.set(this, 'useCustomRegex', true);
        Ember.set(this, 'initialCustomFormat', format);
      }
    },

    actions: {
      remove() {
        if (this.remove) {
          this.remove(this.model);
        }
      },

      useCustomRegex() {
        Ember.set(this, 'useCustomRegex', !Ember.get(this, 'useCustomRegex'));
      }

    },
    useCustomRegexChange: Ember.observer('useCustomRegex', function () {
      const useCustomRegex = Ember.get(this, 'useCustomRegex');

      if (useCustomRegex) {
        Ember.set(this, 'cachedFormat', Ember.get(this, 'config.options.format'));
        Ember.set(this, 'config.options.format', Ember.get(this, 'initialCustomFormat'));
      } else {
        Ember.set(this, 'config.options.format', Ember.get(this, 'cachedFormat'));
      }
    }),
    firstMount: Ember.computed('mounts.[]', function () {
      return Ember.get(this, 'mounts').get('firstObject');
    })
  });

  _exports.default = _default;
});