define("ui/initializers/extend-jquery", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    // Define some more easings and inject into jQuery
    _jquery.default.extend(_jquery.default.easing, {
      easeOutBack(x, t, b, c, d, s) {
        if (s === undefined) {
          s = 1.70158;
        }

        return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
      },

      easeOutCubic(x, t, b, c, d) {
        return c * ((t = t / d - 1) * t * t + 1) + b;
      }

    });
  }

  var _default = {
    name: 'extend-jquery',
    initialize
  };
  _exports.default = _default;
});