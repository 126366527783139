define("ui/authenticated/embed/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect(params) {
      if (params.path.indexOf('dashboard') === 0) {
        if (window.top !== window) {
          const page = params.path.substr(9);
          window.top.postMessage({
            action: 'dashboard',
            page
          });
        }

        return;
      }

      const r = `/c/${params.path}`;
      this.replaceWith(r);
    }

  });

  _exports.default = _default;
});