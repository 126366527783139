define("ui/components/project-quota-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OdJf7yDn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"td\"],[14,0,\"pr-10\"],[12],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"currentQuota\",\"editing\",\"quota\"],[[35,4],[35,3],[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"pr-10\"],[12],[2,\"\\n  \"],[1,[30,[36,6],null,[[\"editing\",\"quota\",\"key\"],[[35,3],[35,0],\"projectLimit\"]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"pr-10\"],[12],[2,\"\\n  \"],[1,[30,[36,6],null,[[\"editing\",\"quota\",\"key\"],[[35,3],[35,0],\"namespaceLimit\"]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\" \"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"input-group-btn\"],[12],[2,\"\\n\"],[6,[37,7],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"btn bg-primary btn-sm\"],[24,4,\"button\"],[4,[38,2],[[32,0],[35,1],[35,0]],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon icon-minus\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"quota\",\"remove\",\"action\",\"editing\",\"currentQuota\",\"resource-quota-select\",\"input-resource-quota\",\"if\"]}",
    "moduleName": "ui/components/project-quota-row/template.hbs"
  });

  _exports.default = _default;
});