define("ui/components/cluster/cis/scan/detail/nodes-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pBPIn9MC",
    "block": "{\"symbols\":[\"node\"],\"statements\":[[10,\"table\"],[14,\"width\",\"100%\"],[14,0,\"table small mb-10 fully-bordered solid\"],[12],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n        \"],[10,\"tr\"],[14,0,\"group-row\"],[12],[2,\"\\n            \"],[10,\"td\"],[14,0,\"pl-15\"],[14,\"colspan\",\"2\"],[12],[1,[30,[36,0],[\"cis.scan.detail.table.nodesTable.title\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"cluster/cis/scan/detail/nodes-table/table-row\",[],[[\"@model\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"nodes\",\"-track-array\",\"each\"]}",
    "moduleName": "ui/components/cluster/cis/scan/detail/nodes-table/template.hbs"
  });

  _exports.default = _default;
});