define("ui/ingresses/run/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rj+3h1mj",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"cancel\",\"certificates\",\"done\",\"editing\",\"existing\",\"ingress\",\"namespacedCertificates\"],[[30,[36,1],[[32,0],\"cancel\"],null],[35,0,[\"certificates\"]],[30,[36,1],[[32,0],\"done\"],null],true,[35,0,[\"existing\"]],[35,0,[\"ingress\"]],[35,0,[\"namespacedcertificates\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\",\"new-edit-ingress\"]}",
    "moduleName": "ui/ingresses/run/template.hbs"
  });

  _exports.default = _default;
});