define("ui/components/settings/theme-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "se1+2Ddb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"userPreferencesPage.theme.header\"],null]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"box\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container-theme-toggle row\"],[12],[2,\"\\n    \"],[11,\"div\"],[16,0,[31,[\"col span-4 bg-default \",[30,[36,3],[[30,[36,2],[[35,1],\"ui-light\"],null],\"active\",\"\"],null]]]],[4,[38,4],[[32,0],\"changeTheme\",\"ui-light\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon icon-sun icon-3x vertical-middle\"],[12],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"vertical-middle\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"userPreferencesPage.theme.light\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"div\"],[16,0,[31,[\"col span-4 bg-default \",[30,[36,3],[[30,[36,2],[[35,1],\"ui-auto\"],null],\"active\",\"\"],null]]]],[4,[38,4],[[32,0],\"changeTheme\",\"ui-auto\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon icon-auto icon-3x vertical-middle\"],[12],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"vertical-middle\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"userPreferencesPage.theme.auto\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"div\"],[16,0,[31,[\"col span-4 bg-default \",[30,[36,3],[[30,[36,2],[[35,1],\"ui-dark\"],null],\"active\",\"\"],null]]]],[4,[38,4],[[32,0],\"changeTheme\",\"ui-dark\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon icon-moon icon-3x vertical-middle\"],[12],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"vertical-middle\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"userPreferencesPage.theme.dark\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"help-block text-center\"],[12],[1,[30,[36,0],[\"userPreferencesPage.theme.autoDetail\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"theme\",\"eq\",\"if\",\"action\"]}",
    "moduleName": "ui/components/settings/theme-toggle/template.hbs"
  });

  _exports.default = _default;
});