define("ui/authenticated/project/project-catalogs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ryeqs7YJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"catalogSettings.header\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"right-buttons\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bg-primary\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"add\"],null],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"catalogSettings.more.addActionLabel\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-12\"],[12],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"icon\",\"color\",\"message\"],[\"icon-info\",\"bg-info mb-0 mt-10\",[30,[36,0],[\"globalDnsPage.entriesPage.istioHelp\"],[[\"htmlSafe\"],[true]]]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,6],null,[[\"catalogs\",\"mode\"],[[35,5],\"project\"]]]],[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"footer-actions mt-20\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bg-transparent\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"goBack\"],null],[12],[1,[30,[36,0],[\"generic.back\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"banner-message\",\"action\",\"istio\",\"if\",\"filtered\",\"custom-catalog\"]}",
    "moduleName": "ui/authenticated/project/project-catalogs/template.hbs"
  });

  _exports.default = _default;
});