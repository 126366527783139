define("ui/components/cluster/cis/scan/detail/table-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y96Apn/R",
    "block": "{\"symbols\":[],\"statements\":[[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"td\"],[14,\"valign\",\"middle\"],[14,0,\"row-check\"],[14,5,\"padding-top: 2px;\"],[12],[2,\"\\n\"],[6,[37,3],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[11,\"i\"],[24,\"role\",\"button\"],[16,0,[31,[\"icon icon-chevron-right eased text-small text-muted \",[30,[36,3],[[35,2],\"icon-rotate-90\"],null]]]],[4,[38,4],[[32,0],\"toggle\"],null],[12],[10,\"span\"],[14,0,\"visually-hidden\"],[12],[2,\"Open accordion\"],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"td\"],[14,\"width\",\"100\"],[14,0,\"pr-30\"],[12],[8,\"badge-state\",[[24,0,\"text-center full-width\"]],[[\"@model\"],[[34,6]]],null],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[35,0,[\"id\"]]],[13],[2,\"\\n    \"],[10,\"td\"],[14,0,\"vertical-top pt-15\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-small\"],[12],[1,[35,0,[\"description\"]]],[13],[2,\"\\n\"],[6,[37,3],[[35,0,[\"remediation\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"text-small text-error mt-5\"],[12],[1,[30,[36,1],[\"cis.scan.detail.table.nodesTable.row.remediation\"],[[\"remediation\"],[[35,0,[\"remediation\"]]]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"td\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,7],[[35,2],[35,5]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[14,\"colspan\",\"5\"],[14,0,\"p-20\"],[12],[2,\"\\n            \"],[8,\"cluster/cis/scan/detail/nodes-table\",[],[[\"@nodes\"],[[34,0,[\"nodes\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"t\",\"expanded\",\"if\",\"action\",\"hasExpandableContent\",\"badgeState\",\"and\"]}",
    "moduleName": "ui/components/cluster/cis/scan/detail/table-row/template.hbs"
  });

  _exports.default = _default;
});