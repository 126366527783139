define("ui/authenticated/project/hpa/detail/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "03jouvSa",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"model\",\"deployments\",\"apiServices\",\"mode\",\"done\",\"cancel\"],[[35,1,[\"hpa\"]],[35,1,[\"deployments\"]],[35,1,[\"apiServices\"]],\"edit\",[30,[36,0],[[32,0],\"back\"],null],[30,[36,0],[[32,0],\"cancel\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"cru-hpa\"]}",
    "moduleName": "ui/authenticated/project/hpa/detail/edit/template.hbs"
  });

  _exports.default = _default;
});