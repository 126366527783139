define("ui/components/form-env-var/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tEwcMuVc",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"label\"],\"statements\":[[6,[37,7],null,[[\"classNames\",\"bulkActions\",\"rowActions\",\"paging\",\"search\",\"sortBy\",\"stickyHeader\",\"descending\",\"headers\",\"body\"],[\"grid fixed mb-0 sortable-table\",false,false,false,true,[35,6],false,[35,5],[35,4],[35,3]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"row\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[15,\"data-title\",[31,[[30,[36,0],[\"formEnvVar.labels.key\"],null],\":\"]]],[14,0,\"force-wrap\"],[12],[1,[32,3,[\"key\"]]],[13],[2,\"\\n      \"],[10,\"td\"],[15,\"data-title\",[31,[[30,[36,0],[\"formEnvVar.labels.value\"],null],\":\"]]],[14,0,\"force-wrap\"],[12],[1,[32,3,[\"value\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"norows\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[15,\"colspan\",[31,[[32,1,[\"fullColspan\"]]]]],[14,0,\"text-center text-muted pt-20 pb-20\"],[12],[1,[30,[36,0],[\"formEnvVar.noData\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2,3]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\",\"model\",\"headers\",\"descending\",\"sortBy\",\"sortable-table\"]}",
    "moduleName": "ui/components/form-env-var/template.hbs"
  });

  _exports.default = _default;
});