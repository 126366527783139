define("ui/catalog-tab/launch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0UiwIaDX",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"cancel\",\"catalogApp\",\"catalogTemplate\",\"namespaceResource\",\"namespaces\",\"parentRoute\",\"selectedTemplateUrl\",\"templateKind\",\"templateResource\",\"upgrade\",\"versionLinks\",\"versionsArray\",\"istio\"],[[30,[36,3],[[32,0],\"cancel\"],null],[35,1,[\"catalogApp\"]],[35,1,[\"catalogTemplate\"]],[35,1,[\"namespace\"]],[35,1,[\"namespaces\"]],[35,2],[35,1,[\"catalogTemplateUrl\"]],[35,1,[\"tplKind\"]],[35,1,[\"tpl\"]],[35,1,[\"upgradeTemplate\"]],[35,1,[\"versionLinks\"]],[35,1,[\"versionsArray\"]],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"istio\",\"model\",\"parentRoute\",\"action\",\"new-catalog\"]}",
    "moduleName": "ui/catalog-tab/launch/template.hbs"
  });

  _exports.default = _default;
});