define("ui/components/notifier/notifier-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bLZmHSp1",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"row\",\"dt\"],\"statements\":[[6,[37,8],null,[[\"tableClassNames\",\"classNames\",\"bulkActions\",\"paging\",\"search\",\"sortBy\",\"descending\",\"headers\",\"body\"],[\"bordered\",\"grid\",true,true,true,[35,7],[35,6],[35,5],[35,4]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"row\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"model\"],[[32,3]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"nomatch\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[15,\"colspan\",[31,[[32,1,[\"fullColspan\"]]]]],[14,0,\"text-center text-muted pt-20 pb-20\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"notifierPage.index.table.noMatch\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"norows\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[15,\"colspan\",[31,[[32,1,[\"fullColspan\"]]]]],[14,0,\"text-center text-muted pt-20 pb-20\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"notifierPage.index.table.noData\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2,3,4]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\",\"notifier/notifier-row\",\"filteredNotifiers\",\"headers\",\"descending\",\"sortBy\",\"sortable-table\"]}",
    "moduleName": "ui/components/notifier/notifier-table/template.hbs"
  });

  _exports.default = _default;
});