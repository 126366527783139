define("ui/authenticated/cluster/notifier/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rwWJLtNc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,0,[\"currentCluster\",\"isReady\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"color\"],[\"bg-warning\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[30,[36,1],[\"banner.notifiers\"],[[\"dashboardLink\",\"docsBase\",\"htmlSafe\"],[[35,0,[\"dashboardLink\"]],[35,0,[\"docsBase\"]],true]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"section\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pull-left pt-10\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[1,[30,[36,1],[\"notifierPage.notifiers\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"right-buttons\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bg-primary\"],[24,4,\"button\"],[4,[38,4],[[32,0],\"showNewEditModal\"],null],[12],[1,[30,[36,1],[\"notifierPage.index.addActionLabel\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,6],null,[[\"model\"],[[35,5]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"scope\",\"t\",\"banner-message\",\"if\",\"action\",\"notifiers\",\"notifier/notifier-table\"]}",
    "moduleName": "ui/authenticated/cluster/notifier/index/template.hbs"
  });

  _exports.default = _default;
});