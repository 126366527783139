define("ui/components/modal-new-vct/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0V5VAo1q",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"model\",\"namespace\",\"selectNamespace\",\"mode\",\"actuallySave\",\"doSave\",\"cancel\",\"workload\"],[[35,2],[35,4],false,[30,[36,3],[[35,2,[\"id\"]],\"edit\",\"new\"],null],false,[30,[36,1],[[32,0],\"doSave\"],null],[30,[36,1],[[32,0],\"cancel\"],null],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"workload\",\"action\",\"model\",\"if\",\"namespace\",\"cru-volume-claim-template\"]}",
    "moduleName": "ui/components/modal-new-vct/template.hbs"
  });

  _exports.default = _default;
});