define("ui/logout/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: Ember.inject.service(),

    beforeModel(transition) {
      if (Ember.get(this, 'app.environment') === 'development') {
        transition.send('logout');
      } else {
        window.location.href = '/dashboard/auth/logout';
      }
    }

  });

  _exports.default = _default;
});