define("ui/components/new-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GM2n4NFh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,6],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"pt-15 pb-30\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[1,[30,[36,0],[\"newPassword.password\"],null]],[1,[34,1]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inline-form\"],[12],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"type\",\"placeholder\",\"value\"],[\"password\",[30,[36,0],[\"newPassword.placeholder\"],null],[35,2]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pt-15 pb-30\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[1,[30,[36,0],[\"newPassword.confirmPassword\"],null]],[1,[34,1]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inline-form\"],[12],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"type\",\"placeholder\",\"value\"],[\"password\",[30,[36,0],[\"newPassword.placeholder\"],null],[35,4]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,6],[[35,5],\"hide\",\"\"],null]]]],[12],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"newPassword.passwordNoMatch\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"field-required\",\"newPassword\",\"input\",\"confirmPassword\",\"passwordsMatch\",\"if\"]}",
    "moduleName": "ui/components/new-password/template.hbs"
  });

  _exports.default = _default;
});