define("ui/components/modal-drain-node/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iIFp9eIX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container-header-text\"],[12],[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[35,0,[\"length\"]],1],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],[\"drainNode.titleOne\"],[[\"name\"],[[35,0,[\"firstObject\",\"displayName\"]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,1],[\"drainNode.titleMultiple\"],[[\"count\"],[[35,0,[\"length\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"section\"],[14,0,\"p-20 pt-0\"],[12],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"selection\"],[[35,4]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,7],null,[[\"editing\",\"editLabel\",\"save\",\"cancel\"],[true,\"drainNode.action\",[30,[36,6],[[32,0],\"drain\"],null],[30,[36,6],[[32,0],\"cancel\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"resources\",\"t\",\"eq\",\"if\",\"selection\",\"drain-node\",\"action\",\"save-cancel\"]}",
    "moduleName": "ui/components/modal-drain-node/template.hbs"
  });

  _exports.default = _default;
});