define("ui/pod-graphs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MPfdo+8Y",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,4,[\"currentCluster\",\"isMonitoringReady\"]]],null,[[\"default\"],[{\"statements\":[[11,\"div\"],[24,0,\"pull-right grafana-link\"],[24,5,\"cursor: pointer;\"],[4,[38,1],[[32,0],\"goToGrafana\",[35,0,[\"grafanaUrl\"]]],[[\"bubbles\"],[false]]],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inline-block vertical-middle grafana\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"vertical-middle text-muted\"],[12],[1,[30,[36,2],[\"metricsAction.grafana.label\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"mb-20 clearfix\"],[12],[13],[2,\"\\n\"],[1,[30,[36,3],null,[[\"resourceId\"],[[35,0,[\"id\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"action\",\"t\",\"pod-metrics\",\"scope\",\"if\"]}",
    "moduleName": "ui/pod-graphs/template.hbs"
  });

  _exports.default = _default;
});