define("ui/authenticated/project/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3EeqPAoq",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"color\"],[\"bg-warning m-0 mb-15\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[30,[36,1],[\"clusterDashboard.systemProject\"],[[\"appName\"],[[35,0,[\"appName\"]]]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"settings\",\"t\",\"banner-message\",\"showSystemProjectWarning\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "ui/authenticated/project/template.hbs"
  });

  _exports.default = _default;
});