define("ui/authenticated/project/container-log/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qk+2+8nZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"mr-20 ml-20 mt-20 mb-20\"],[12],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"instance\",\"containerName\",\"showProtip\",\"logHeight\",\"dismiss\"],[[35,1,[\"pod\"]],[35,1,[\"containerName\"]],false,185,[30,[36,0],[[32,0],\"cancel\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"container-logs\"]}",
    "moduleName": "ui/authenticated/project/container-log/template.hbs"
  });

  _exports.default = _default;
});