define("ui/models/node", ["exports", "@rancher/ember-api-store/models/resource", "shared/utils/util", "ui/utils/constants", "ui/mixins/state-counts", "@rancher/ember-api-store/utils/denormalize", "shared/mixins/resource-usage", "shared/mixins/grafana"], function (_exports, _resource, _util, _constants, _stateCounts, _denormalize, _resourceUsage, _grafana) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UNSCHEDULABLE_KEYS = ['node-role.kubernetes.io/etcd', 'node-role.kubernetes.io/controlplane'];
  const UNSCHEDULABLE_EFFECTS = ['NoExecute', 'NoSchedule'];
  const CONTAINERD = 'containerd://';

  var Node = _resource.default.extend(_grafana.default, _stateCounts.default, _resourceUsage.default, {
    modalService: Ember.inject.service('modal'),
    settings: Ember.inject.service(),
    prefs: Ember.inject.service(),
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    nodes: (0, _denormalize.hasMany)('clusterId', 'node', 'clusterId'),
    type: 'node',
    containerD: CONTAINERD,
    isContainerD: false,
    grafanaDashboardName: 'Nodes',
    grafanaResourceId: Ember.computed.alias('ipAddress'),
    cluster: (0, _denormalize.reference)('clusterId', 'cluster'),
    nodePool: (0, _denormalize.reference)('nodePoolId'),
    displayIp: Ember.computed.or('externalIpAddress', 'ipAddress'),

    init() {
      this._super(...arguments);

      this.defineStateCounts('arrangedInstances', 'instanceStates', 'instanceCountSort');
    },

    availableActions: Ember.computed('actionLinks.{cordon,drain,uncordon}', 'canScaleDown', 'links.nodeConfig', function () {
      let l = Ember.get(this, 'links');
      const a = Ember.get(this, 'actionLinks') || {};
      let out = [{
        label: 'action.cordon',
        icon: 'icon icon-pause',
        action: 'cordon',
        enabled: !!a.cordon,
        bulkable: true
      }, {
        label: 'action.uncordon',
        icon: 'icon icon-play',
        action: 'uncordon',
        enabled: !!a.uncordon,
        bulkable: true
      }, {
        label: 'action.drain',
        icon: 'icon icon-snapshot',
        action: 'drain',
        enabled: !!a.drain,
        bulkable: true
      }, {
        label: 'action.scaledown',
        icon: 'icon icon-arrow-circle-up icon-rotate-180',
        action: 'scaledown',
        enabled: this.canScaleDown,
        bulkable: true
      }, {
        label: 'action.stopDrain',
        icon: 'icon icon-stop',
        action: 'stopDrain',
        enabled: !!a.stopDrain,
        bulkable: true
      }, {
        divider: true
      }, {
        label: 'action.nodeConfig',
        icon: 'icon icon-download',
        action: 'nodeConfig',
        enabled: !!l.nodeConfig
      }, {
        divider: true
      }];
      return out;
    }),
    canScaleDown: Ember.computed('actionLinks.scaledown', 'nodePool.quantity', function () {
      const actions = Ember.get(this, 'actionLinks');
      const nodePool = Ember.get(this, 'nodePool');
      return !!(actions !== null && actions !== void 0 && actions.scaledown) && (nodePool === null || nodePool === void 0 ? void 0 : nodePool.quantity) > 1;
    }),
    displayName: Ember.computed('id', 'name', 'nodeName.length', 'nodes', 'requestedHostname', function () {
      let name = Ember.get(this, 'name');

      if (name) {
        return name;
      }

      name = Ember.get(this, 'nodeName');

      if (name) {
        if (name.match(/[a-z]/i)) {
          name = this.parseNodeName(name);
        }

        return name;
      }

      name = Ember.get(this, 'requestedHostname');

      if (name) {
        return name;
      }

      return `(${Ember.get(this, 'id')})`;
    }),
    rolesArray: Ember.computed('etcd', 'controlPlane', 'worker', function () {
      return ['etcd', 'controlPlane', 'worker'].filter(x => !!Ember.get(this, x));
    }),
    displayRoles: Ember.computed('intl.locale', 'rolesArray.[]', function () {
      const intl = Ember.get(this, 'intl');
      const roles = Ember.get(this, 'rolesArray');

      if (roles.length >= 3) {
        return [intl.t('generic.all')];
      }

      return roles.map(role => {
        let key = `model.machine.role.${role}`;

        if (intl.exists(key)) {
          return intl.t(key);
        }

        return key;
      });
    }),
    sortRole: Ember.computed('rolesArray.[]', function () {
      let roles = Ember.get(this, 'rolesArray');

      if (roles.length >= 3) {
        return 1;
      }

      if (roles.includes('controlPlane')) {
        return 2;
      }

      if (roles.includes('etcd')) {
        return 3;
      }

      return 4;
    }),
    isUnschedulable: Ember.computed('taints.@each.{effect,key}', function () {
      const taints = Ember.get(this, 'taints') || [];
      return taints.some(taint => UNSCHEDULABLE_KEYS.includes(taint.key) && UNSCHEDULABLE_EFFECTS.includes(taint.effect));
    }),
    isK3sNode: Ember.computed('labels', function () {
      const labels = Ember.get(this, 'labels') || {};
      return Object.prototype.hasOwnProperty.call(labels, _constants.default.LABEL.NODE_INSTANCE_TYPE);
    }),
    k3sNodeArgs: Ember.computed('annotations', function () {
      const {
        annotations
      } = this;
      const nodeArgs = annotations[_constants.default.LABEL.K3S_NODE_ARGS] ? JSON.parse(annotations[_constants.default.LABEL.K3S_NODE_ARGS]) : [];
      return nodeArgs.join(' ');
    }),
    k3sNodeEnvVar: Ember.computed('annotations', function () {
      const {
        annotations
      } = this;
      const nodeEnv = annotations[_constants.default.LABEL.K3S_NODE_ENV] ? JSON.parse(annotations[_constants.default.LABEL.K3S_NODE_ENV]) : {};
      const nodeEnvArr = [];
      Object.keys(nodeEnv).forEach(envKey => {
        const out = {
          key: envKey,
          value: nodeEnv[envKey]
        };
        nodeEnvArr.push(out);
      });
      return nodeEnvArr;
    }),
    osBlurb: Ember.computed('info.os.operatingSystem', function () {
      var out = Ember.get(this, 'info.os.operatingSystem') || '';
      out = out.replace(/\s+\(.*?\)/, ''); // Remove details in parens

      out = out.replace(/;.*$/, ''); // Or after semicolons
      // RHEL 7 uses 'Red Hat Enterprise Linux Server', RHEL 8 uses 'Red Hat Enterprise Linux'

      out = out.replace(/(Red Hat Enterprise Linux(\sServer|))/, 'RHEL'); // That's kinda long

      return out;
    }),
    engineIcon: Ember.computed('info.os.dockerVersion', function () {
      if ((Ember.get(this, 'info.os.dockerVersion') || '').startsWith(CONTAINERD)) {
        return 'icon-container-d';
      }

      return 'icon-docker';
    }),
    versionBlurb: Ember.computed('info.os.dockerVersion', 'isContainerD', function () {
      let version = Ember.get(this, 'info.os.dockerVersion') || '';

      if (version.startsWith(CONTAINERD)) {
        version = version.substr(CONTAINERD.length);

        if (!this.isContainerD) {
          Ember.run.next(() => Ember.set(this, 'isContainerD', true));
        }
      } else {
        if (this.isContainerD) {
          Ember.run.next(() => Ember.set(this, 'isContainerD', false));
        }
      }

      const idx = version.indexOf('+');

      if (idx > 0) {
        version = version.substr(0, idx);
      }

      return version;
    }),
    osInfo: Ember.computed('labels', function () {
      const labels = Ember.get(this, 'labels') || {};
      return labels['beta.kubernetes.io/os'];
    }),
    //  or they will not be pulled in correctly.
    displayEndpoints: Ember.computed('publicEndpoints.@each.{ipAddress,port,serviceId,instanceId}', function () {
      var store = Ember.get(this, 'clusterStore');
      return (Ember.get(this, 'publicEndpoints') || []).map(endpoint => {
        if (!endpoint.service) {
          endpoint.service = store.getById('service', endpoint.serviceId);
        }

        endpoint.instance = store.getById('instance', endpoint.instanceId);
        return endpoint;
      });
    }),
    // If you use this you must ensure that services and containers are already in the store
    requireAnyLabelStrings: Ember.computed(`labels.${_constants.default.LABEL.REQUIRE_ANY}`, 'labels', function () {
      return ((Ember.get(this, 'labels') || {})[_constants.default.LABEL.REQUIRE_ANY] || '').split(/\s*,\s*/).filter(x => x.length > 0 && x !== _constants.default.LABEL.SYSTEM_TYPE);
    }),

    parseNodeName(nameIn) {
      const suffix = nameIn.split('.').slice(1).join('.');
      const nodesWithSameSuffix = (this.nodes || []).filter(node => (node.nodeName || '').endsWith(suffix));

      if (nodesWithSameSuffix.length === 1) {
        return this.nodeName;
      } else if (nodesWithSameSuffix.length > 1) {
        const neu = nameIn.replace(/\..*$/, '');

        if (neu.match(/^\d+$/)) {
          return this.nodeName;
        } else {
          return neu;
        }
      }

      return nameIn;
    },

    actions: {
      activate() {
        return this.doAction('activate');
      },

      deactivate() {
        return this.doAction('deactivate');
      },

      cordon() {
        return this.doAction('cordon');
      },

      uncordon() {
        return this.doAction('uncordon');
      },

      scaledown() {
        return this.doAction('scaledown');
      },

      drain() {
        Ember.get(this, 'modalService').toggleModal('modal-drain-node', {
          escToClose: true,
          resources: [this]
        });
      },

      stopDrain() {
        return this.doAction('stopDrain');
      },

      newContainer() {
        Ember.get(this, 'router').transitionTo('containers.run', {
          queryParams: {
            hostId: Ember.get(this, 'model.id')
          }
        });
      },

      edit() {
        Ember.get(this, 'modalService').toggleModal('modal-edit-host', this);
      },

      nodeConfig() {
        var url = this.linkFor('nodeConfig');

        if (url) {
          (0, _util.download)(url);
        }
      }

    }
  });

  Node.reopenClass({
    defaultSortBy: 'name,hostname'
  });
  var _default = Node;
  _exports.default = _default;
});