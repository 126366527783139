define("ui/components/project-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AM/xZaM4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"td\"],[14,\"valign\",\"middle\"],[14,0,\"row-check\"],[14,5,\"padding-top: 2px;\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"nodeId\"],[[35,0,[\"id\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,2,[\"state\"]]]]],[14,0,\"state\"],[12],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[30,[36,4],[\"projects.table.project.label\"],null],\":\"]]],[12],[2,\"\\n\"],[6,[37,5],null,[[\"class\",\"route\",\"model\"],[\"clip project-link\",\"authenticated.project\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[35,0,[\"displayName\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[30,[36,4],[\"projects.table.created.label\"],null],\":\"]]],[12],[2,\"\\n  \"],[1,[30,[36,6],[[35,0,[\"created\"]]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[30,[36,4],[\"generic.actions\"],null],\":\"]]],[14,0,\"actions\"],[12],[2,\"\\n  \"],[1,[30,[36,7],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"check-box\",\"dt\",\"badge-state\",\"t\",\"link-to\",\"date-calendar\",\"action-menu\"]}",
    "moduleName": "ui/components/project-row/template.hbs"
  });

  _exports.default = _default;
});