define("ui/templates/tooltip-action-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ch+75eB/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"tooltip-content-inner tooltip-dot\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"model\",\"showPrimary\",\"inTooltip\",\"class\"],[[35,0],false,true,\"pull-right tooltip-more-actions\"]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"display-name\"],[12],[1,[34,2]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"display-ip\"],[12],[1,[30,[36,3],[[35,0,[\"displayIp\"]]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action-menu\",\"displayName\",\"format-ip\"]}",
    "moduleName": "ui/templates/tooltip-action-menu.hbs"
  });

  _exports.default = _default;
});