define("ui/templates/tooltip-static", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0JxcFLvl",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "moduleName": "ui/templates/tooltip-static.hbs"
  });

  _exports.default = _default;
});