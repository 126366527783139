define("ui/templates/tooltip-container-dot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S/9CJ2eZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"tooltip-content-inner tooltip-dot\"],[12],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"model\",\"showPrimary\",\"inTooltip\",\"class\"],[[35,0],false,true,\"pull-right tooltip-more-actions\"]]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"display-name\"],[12],[10,\"a\"],[15,6,[31,[[30,[36,3],[\"pod\",[35,0,[\"id\"]]],null]]]],[12],[1,[34,4]],[13],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"clearfix\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[1,[30,[36,5],null,[[\"classNames\",\"model\"],[\"btn-xs\",[35,0]]]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"display-ip pull-left pr-5\"],[12],[1,[30,[36,6],[[35,0,[\"displayIp\"]]],null]],[13],[2,\"\\n\"],[6,[37,7],[[35,0,[\"node\",\"displayName\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"display-ip pull-left pr-5\"],[12],[2,\"\\n        \"],[1,[35,0,[\"node\",\"displayName\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[6,[37,7],[[35,0,[\"showTransitioningMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"text-small bg-body p-5 mt-5 force-wrap \",[34,0,[\"stateColor\"]]]]],[12],[1,[30,[36,1],[[35,0,[\"transitioningMessage\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"uc-first\",\"action-menu\",\"href-to\",\"displayName\",\"badge-state\",\"format-ip\",\"if\"]}",
    "moduleName": "ui/templates/tooltip-container-dot.hbs"
  });

  _exports.default = _default;
});