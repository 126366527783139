define("ui/authenticated/project/console/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/wqUppnN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"shell-popup\"],[12],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"instance\",\"command\",\"windows\",\"containerName\",\"showProtip\",\"cols\",\"rows\",\"dismiss\",\"disconncted\"],[[35,4],[35,3],[35,2],[35,1],false,80,24,[30,[36,0],[[32,0],\"cancel\"],null],[30,[36,0],[[32,0],\"cancel\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"containerName\",\"windows\",\"command\",\"model\",\"container-shell\"]}",
    "moduleName": "ui/authenticated/project/console/template.hbs"
  });

  _exports.default = _default;
});