define("ui/templates/-ns-quota-progressbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qqSY8U3y",
    "block": "{\"symbols\":[\"tt\"],\"statements\":[[10,\"table\"],[14,0,\"table fixed tooltip-table\"],[14,5,\"max-width: 300px;\"],[12],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[12],[1,[32,1,[\"label\"]]],[2,\":\"],[13],[2,\"\\n        \"],[10,\"td\"],[12],[1,[32,1,[\"value\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"-track-array\",\"each\"]}",
    "moduleName": "ui/templates/-ns-quota-progressbar.hbs"
  });

  _exports.default = _default;
});