define("ui/container-graphs/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel() {
      if (window.ShellQuote) {
        return;
      } else {
        return emberAutoImportDynamic("shell-quote").then(module => {
          window.ShellQuote = module.default;
          return module.default;
        });
      }
    },

    model(params) {
      const pod = Ember.get(this, 'store').find('pod', params.pod_id);
      return Ember.RSVP.hash({
        pod
      }).then(hash => {
        const container = Ember.get(hash, 'pod.containers').findBy('name', params.container_name);

        if (!container) {
          this.replaceWith('authenticated.project.index');
        }

        return container;
      });
    },

    actions: {
      goToGrafana(grafanaUrl) {
        window.open(grafanaUrl, '_blank');
      }

    }
  });

  _exports.default = _default;
});