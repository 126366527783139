define("ui/components/modal-rotate-encryption-key/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "be3uTjgn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container-header-text\"],[12],[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"modalRotateEncryption.title\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,1],null,[[\"color\",\"message\"],[\"bg-warning\",[30,[36,0],[\"modalRotateEncryption.backupWarning\"],null]]]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-12\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"modalRotateEncryption.lastBackup\"],[[\"backupName\",\"backupTime\"],[[35,2,[\"backupName\"]],[35,2,[\"backupTime\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"top-errors\",[],[[\"@errors\"],[[34,3]]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"footer-actions\"],[12],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"class\",\"cancel\",\"editLabel\",\"editing\",\"save\"],[\"pt-0\",[30,[36,4],[[32,0],\"cancel\"],null],\"generic.confirm\",true,[30,[36,4],[[32,0],\"rotate\"],null]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"banner-message\",\"lastBackup\",\"errors\",\"action\",\"save-cancel\"]}",
    "moduleName": "ui/components/modal-rotate-encryption-key/template.hbs"
  });

  _exports.default = _default;
});