define("ui/components/modal-edit-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w0m18Fjv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"modalEditPassword.edit.title\"],[[\"appName\"],[[35,1,[\"appName\"]]]]]],[13],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"help-text\"],[12],[1,[30,[36,0],[\"modalEditPassword.edit.help\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[1,[30,[36,6],null,[[\"complete\",\"showCurrent\",\"showDeleteTokens\",\"cancel\",\"editButton\",\"user\"],[[30,[36,5],[[32,0],\"complete\"],null],true,true,[30,[36,5],[[32,0],\"cancel\"],null],\"modalEditPassword.actionButton\",[35,4]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"settings\",\"showHelp\",\"if\",\"user\",\"action\",\"input-edit-password\"]}",
    "moduleName": "ui/components/modal-edit-password/template.hbs"
  });

  _exports.default = _default;
});