define("ui/components/settings/user-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sEW6FjmU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"userPreferencesPage.generalInfo.header\"],null]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"box\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-12\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[1,[30,[36,0],[\"userPreferencesPage.generalInfo.name.label\"],null]],[13],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"editable\",\"value\"],[[35,2],[35,1,[\"name\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-12\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[1,[30,[36,0],[\"userPreferencesPage.generalInfo.username.label\"],null]],[13],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"editable\",\"value\"],[[35,2],[35,1,[\"login\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row text-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-12 text-left\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn bg-primary\"],[24,4,\"button\"],[4,[38,4],[[32,0],\"editPassword\"],null],[12],[1,[30,[36,0],[\"userPreferencesPage.generalInfo.password.text\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"account\",\"editing\",\"input-or-display\",\"action\"]}",
    "moduleName": "ui/components/settings/user-info/template.hbs"
  });

  _exports.default = _default;
});