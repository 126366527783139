define("ui/application/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    language: Ember.inject.service('user-language'),
    modal: Ember.inject.service(),
    prefs: Ember.inject.service(),
    settings: Ember.inject.service(),
    previousParams: null,
    previousRoute: null,
    loadingShown: false,
    loadingId: 0,
    hideTimer: null,
    previousLang: null,
    shortcuts: {
      'shift+l': 'langToggle'
    },

    beforeModel() {
      this.updateWindowTitle();
      return (async () => {
        if (!window.Prettycron) {
          window.Prettycron = await emberAutoImportDynamic("prettycron");
        } // Find out if auth is enabled


        return Ember.get(this, 'access').detect().finally(() => {
          return Ember.get(this, 'language').initLanguage();
        });
      })();
    },

    model(params, transition) {
      transition.finally(() => {
        this.controllerFor('application').setProperties({
          state: null,
          code: null,
          error_description: null
        });
      });

      if (params.isPopup) {
        this.controllerFor('application').set('isPopup', true);
      }
    },

    actions: {
      didTransition() {
        this.notifyAction('did-transition');
      },

      loading(transition) {
        this.incrementProperty('loadingId');
        let id = Ember.get(this, 'loadingId');
        Ember.run.cancel(Ember.get(this, 'hideTimer')); // console.log('Loading', id);

        this.notifyAction('need-to-load');

        if (!Ember.get(this, 'loadingShown')) {
          Ember.set(this, 'loadingShown', true); // console.log('Loading Show', id);

          this.notifyLoading(true);
          Ember.run.schedule('afterRender', () => {
            $('#loading-underlay').stop().show().fadeIn({
              // eslint-disable-line
              duration: 100,
              queue: false,
              easing: 'linear',
              complete: Ember.run.schedule('afterRender', function () {
                // eslint-disable-line
                $('#loading-overlay').stop().show().fadeIn({
                  duration: 200,
                  queue: false,
                  easing: 'linear'
                }); // eslint-disable-line
              })
            });
          });
        }

        transition.finally(() => {
          var self = this;

          function hide() {
            // console.log('Loading hide', id);
            Ember.set(self, 'loadingShown', false);
            Ember.run.schedule('afterRender', () => {
              $('#loading-overlay').stop().fadeOut({
                // eslint-disable-line
                duration: 200,
                queue: false,
                easing: 'linear',
                complete: Ember.run.schedule('afterRender', function () {
                  // eslint-disable-line
                  $('#loading-underlay').stop().fadeOut({
                    duration: 100,
                    queue: false,
                    easing: 'linear'
                  }); // eslint-disable-line

                  setTimeout(() => self.notifyLoading(false), 200);
                })
              });
            });
          }

          if (Ember.get(this, 'loadingId') === id) {
            if (transition.isAborted) {
              // console.log('Loading aborted', id, get(this, 'loadingId'));
              Ember.set(this, 'hideTimer', Ember.run.next(hide));
            } else {
              // console.log('Loading finished', id, get(this, 'loadingId'));
              hide();
            }
          }
        });
        return true;
      },

      error(err, transition) {
        /* if we dont abort the transition we'll call the model calls again and fail transition correctly*/
        transition.abort();
        const status = parseInt(err.status, 10);

        if (err && [401, 403].includes(status)) {
          this.send('logout', transition);
          return;
        }

        this.controllerFor('application').set('error', err);
        this.transitionTo('failWhale'); // console.log('Application Error', (err ? err.stack : undefined));
      },

      goToPrevious(def) {
        this.goToPrevious(def);
      },

      finishLogin() {
        this.finishLogin();
      },

      logout(transition, errorMsg) {
        let session = Ember.get(this, 'session');
        let access = Ember.get(this, 'access');
        const isEmbedded = window.top !== window;

        if (isEmbedded) {
          window.top.postMessage({
            action: 'logout'
          });
          return;
        }

        access.clearToken().finally(() => {
          let url;

          if (Ember.get(this, 'app.environment') === 'development') {
            url = `${window.location.origin}/login`;
          } else {
            url = `${window.location.origin}/dashboard/auth/login`;
          }

          Ember.get(this, 'tab-session').clear();
          Ember.set(this, `session.${_constants.default.SESSION.CONTAINER_ROUTE}`, undefined);
          Ember.set(this, `session.${_constants.default.SESSION.ISTIO_ROUTE}`, undefined);
          Ember.set(this, `session.${_constants.default.SESSION.CLUSTER_ROUTE}`, undefined);
          Ember.set(this, `session.${_constants.default.SESSION.PROJECT_ROUTE}`, undefined);

          if (transition && !session.get(_constants.default.SESSION.BACK_TO)) {
            session.set(_constants.default.SESSION.BACK_TO, window.location.href);
          }

          if (Ember.get(this, 'modal.modalVisible')) {
            Ember.get(this, 'modal').toggleModal();
          }

          if (errorMsg) {
            url = `${url}?errorMsg=${errorMsg}`;
          }

          window.location.replace(url);
        });
      },

      langToggle() {
        let svc = Ember.get(this, 'language');
        let cur = svc.getLocale();

        if (cur === 'none') {
          svc.sideLoadLanguage(Ember.get(this, 'previousLang') || 'en-us');
        } else {
          Ember.set(this, 'previousLang', cur);
          svc.sideLoadLanguage('none');
        }
      }

    },
    updateWindowTitle: Ember.observer('settings.appName', function () {
      document.title = Ember.get(this, 'settings.appName');
    }),

    finishLogin() {
      let session = Ember.get(this, 'session');
      let backTo = session.get(_constants.default.SESSION.BACK_TO);
      session.set(_constants.default.SESSION.BACK_TO, undefined);

      if (backTo) {
        // console.log('Going back to', backTo);
        window.location.href = backTo;
      } else {
        this.replaceWith('authenticated');
      }
    },

    notifyLoading(isLoading) {
      this.notifyAction('loading', isLoading);
    },

    notifyAction(action, state) {
      // If embedded, notify outer frame
      const isEmbedded = window !== window.top;

      if (isEmbedded) {
        window.top.postMessage({
          action,
          state
        });
      }
    }

  });

  _exports.default = _default;
});