define("ui/authenticated/cluster/storage/classes/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HI29TY9y",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"mode\",\"model\",\"done\",\"cancel\"],[\"new\",[35,1],[30,[36,0],[[32,0],\"back\"],null],[30,[36,0],[[32,0],\"back\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"cru-storage-class\"]}",
    "moduleName": "ui/authenticated/cluster/storage/classes/new/template.hbs"
  });

  _exports.default = _default;
});