define("ui/authenticated/project/config-maps/detail/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pRxi5Cbf",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"mode\",\"model\"],[\"view\",[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"cru-config-map\"]}",
    "moduleName": "ui/authenticated/project/config-maps/detail/index/template.hbs"
  });

  _exports.default = _default;
});