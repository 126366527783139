define("ui/components/cluster/cis/scan/detail/nodes-table/table-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kViQyC3n",
    "block": "{\"symbols\":[],\"statements\":[[10,\"tr\"],[14,0,\"main-row\"],[12],[2,\"\\n    \"],[10,\"td\"],[14,\"width\",\"100\"],[14,0,\"pl-15 pr-15 vertical-top  pt-15\"],[12],[8,\"badge-state\",[[24,0,\"text-center full-width\"]],[[\"@model\"],[[34,0]]],null],[13],[2,\"\\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[35,1,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"badgeState\",\"model\"]}",
    "moduleName": "ui/components/cluster/cis/scan/detail/nodes-table/table-row/template.hbs"
  });

  _exports.default = _default;
});