define("ui/volumes/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+/bL0R5r",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"model\",\"persistentVolumes\",\"storageClasses\",\"namespace\",\"mode\"],[[35,0,[\"pvc\"]],[35,0,[\"persistentVolumes\"]],[35,0,[\"storageClasses\"]],[35,0,[\"pvc\",\"namespace\"]],\"view\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"cru-persistent-volume-claim\"]}",
    "moduleName": "ui/volumes/detail/template.hbs"
  });

  _exports.default = _default;
});