define("ui/components/container/form-upgrade/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IhCjheuU",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,15],null,[[\"title\",\"detail\",\"status\",\"statusClass\",\"expandAll\",\"expandOnInit\",\"expand\",\"everExpanded\"],[[30,[36,14],[\"formUpgrade.title\"],null],[30,[36,14],[\"formUpgrade.detail\"],[[\"appName\"],[[35,13,[\"appName\"]]]]],[35,12],[35,11],[35,10],[35,9],[30,[36,8],[[32,0],[35,7]],null],true]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,6],[[35,5]],[[\"workload\",\"scaleMode\",\"workloadConfig\",\"editing\",\"isUpgrade\"],[[35,4],[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isUpgrade\",\"editing\",\"workloadConfig\",\"scaleMode\",\"workload\",\"componentName\",\"component\",\"expandFn\",\"action\",\"expandOnInit\",\"expandAll\",\"statusClass\",\"status\",\"settings\",\"t\",\"accordion-list-item\"]}",
    "moduleName": "ui/components/container/form-upgrade/template.hbs"
  });

  _exports.default = _default;
});