define("ui/update-critical-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6XXfclBJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"login\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"serverUrlSetting\",\"initServerUrl\"],[[35,0,[\"serverUrlSetting\"]],[35,0,[\"serverUrl\"]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"settings/server-url\"]}",
    "moduleName": "ui/update-critical-settings/template.hbs"
  });

  _exports.default = _default;
});