define("ui/components/security-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "taHgoTjM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"section\"],[14,0,\"has-tabs clearfix p-0\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"tab-nav\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"route\"],[\"authenticated.project.secrets\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"secretsPage.index.header\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"route\"],[\"authenticated.project.certificates\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"certificatesPage.index.header\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"route\"],[\"authenticated.project.registries\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"registriesPage.index.header\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"right-buttons\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\"]}",
    "moduleName": "ui/components/security-header/template.hbs"
  });

  _exports.default = _default;
});