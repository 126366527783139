define("ui/components/modal-kubeconfig/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yxryUq4M",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[35,5],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"icon icon-spinner icon-spin\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"k8sPage.configFile.generating\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,7],[[30,[36,6],[[35,5],2],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"k8sPage.configFile.usage.helpText\"],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"language\",\"code\"],[\"yaml\",[35,1]]]]],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"tooltipText\",\"buttonText\",\"clipboardText\",\"class\"],[\"\",\"copyToClipboard.tooltip\",[35,1],\"with-clip\"]]]],[2,\"\\n    \"],[10,\"p\"],[14,0,\"mt-10 mb-0\"],[12],[1,[30,[36,0],[\"k8sPage.configFile.usage.downloadText\"],[[\"downloadUrl\",\"htmlSafe\"],[[35,4],true]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"footer-actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bg-primary close-kubeconfig\"],[24,4,\"button\"],[4,[38,8],[[32,0],\"cancel\"],null],[12],[1,[30,[36,0],[\"generic.closeModal\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"kubeconfig\",\"code-block\",\"copy-to-clipboard\",\"downloadUrl\",\"step\",\"eq\",\"if\",\"action\"]}",
    "moduleName": "ui/components/modal-kubeconfig/template.hbs"
  });

  _exports.default = _default;
});