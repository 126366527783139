define("ui/components/modal-new-volume/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "As2Zbzb7",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"model\",\"mode\",\"actuallySave\",\"doSave\",\"cancel\"],[[35,1],\"new\",false,[30,[36,0],[[32,0],\"doSave\"],null],[30,[36,0],[[32,0],\"cancel\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"cru-volume\"]}",
    "moduleName": "ui/components/modal-new-volume/template.hbs"
  });

  _exports.default = _default;
});