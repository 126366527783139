define("ui/components/form-access-modes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7JO8uDZH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[14,0,\"acc-label\"],[12],[1,[30,[36,0],[\"formAccessModes.label\"],null]],[13],[2,\"\\n\"],[6,[37,2],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      \"],[1,[30,[36,5],null,[[\"type\",\"checked\"],[\"checkbox\",[35,1]]]]],[2,\"\\n      \"],[1,[30,[36,0],[\"formAccessModes.accessRWO\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      \"],[1,[30,[36,5],null,[[\"type\",\"checked\"],[\"checkbox\",[35,3]]]]],[2,\"\\n      \"],[1,[30,[36,0],[\"formAccessModes.accessROX\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      \"],[1,[30,[36,5],null,[[\"type\",\"checked\"],[\"checkbox\",[35,4]]]]],[2,\"\\n      \"],[1,[30,[36,0],[\"formAccessModes.accessRWX\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[1,[30,[36,0],[\"formAccessModes.accessRWO\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[1,[30,[36,0],[\"formAccessModes.accessROX\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[1,[30,[36,0],[\"formAccessModes.accessRWX\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"accessRWO\",\"if\",\"accessROX\",\"accessRWX\",\"input\",\"editing\"]}",
    "moduleName": "ui/components/form-access-modes/template.hbs"
  });

  _exports.default = _default;
});