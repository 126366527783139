define("ui/pod-graphs/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel() {
      if (window.ShellQuote) {
        return;
      } else {
        return emberAutoImportDynamic("shell-quote").then(module => {
          window.ShellQuote = module.default;
          return module.default;
        });
      }
    },

    model(params) {
      const pod = Ember.get(this, 'store').find('pod', params.pod_id);

      if (!pod) {
        this.replaceWith('authenticated.project.index');
      }

      return pod;
    },

    actions: {
      goToGrafana(grafanaUrl) {
        window.open(grafanaUrl, '_blank');
      }

    }
  });

  _exports.default = _default;
});