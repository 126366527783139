define("ui/templates/tooltip-basic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BG3XdADc",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[35,0,[\"label\"]]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"t\"]}",
    "moduleName": "ui/templates/tooltip-basic.hbs"
  });

  _exports.default = _default;
});