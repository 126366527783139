define("ui/components/container/form-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R4V2ZGna",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"formImage.label\"],null]],[1,[34,1]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"value\",\"grouped\",\"placeholder\"],[[35,3],[35,2],[30,[36,0],[\"formImage.container.placeholder\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"field-required\",\"suggestions\",\"userInput\",\"input-suggest\"]}",
    "moduleName": "ui/components/container/form-image/template.hbs"
  });

  _exports.default = _default;
});